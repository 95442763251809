/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

// Style import
import "../design/welcome.scss";

// Component import
import Sarus from "@anephenix/sarus";
import { QrPaymentService } from "../services/QrPayment.Service.js";

// MaterialUI import
import Button from "@material-ui/core/Button";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleFilled,
  QrcodeOutlined,
} from "@ant-design/icons";

import { Col, Modal, Row } from "antd";

/* ------------
      WELCOME      
  ------------ */

const payment_wss_url = process.env.REACT_APP_PAYMENT_WSS_URL;
console.log(payment_wss_url);

export default withRouter(function Welcome(props) {
  //* --  STATES  -- *//
  const [cpStatus, setCpStatus] = useState();
  const [statusMsg, setStatusMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [reload, setReload] = useState();
  const [priceInclVat, setPriceInclVat] = useState();
  const [priceExclVat, setPriceExclVat] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const history = useHistory();

  //* -- BACKEND -- *//

  //* --  USE EFFECT  -- *//
  useEffect(() => {
    const evse_id = props.match.params.evse_id;

    QrPaymentService.getStatus(evse_id)
      .then((response) => {
        setCpStatus(response);

        setPriceInclVat(response.user.price.incl_vat);
        setPriceExclVat(response.user.price.excl_vat);
        if (response.user.open == true) {
          if (response.chargepoint.connection_status === "online") {
            switch (response.chargepoint.ocpp_status) {
              case "Available":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist bereit{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(false);
                break;
              case "Preparing":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Fahrzeug angeschlossen{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(false);
                break;
              case "Charging":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Es findet bereits ein Ladevorgang statt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "SuspendedEVSE":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "SuspendedEV":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Finishing":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Reserved":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist reserviert.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Unavailable":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Faulted":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              default:
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
            }
          } else {
            setStatusMsg(
              <p style={{ color: "#ef5350" }}>
                Ladepunkt ist offline.{" "}
                <CloseCircleOutlined
                  style={{
                    fontSize: "16px",
                    transform: "translateY(1px)",
                  }}
                />
              </p>
            );
            setButtonDisabled(true);
          }
        } else {
          setStatusMsg(
            <p style={{ color: "#ef5350" }}>
              Der Ladepunkt ist geschlossen.{" "}
              <CloseCircleOutlined
                style={{
                  fontSize: "16px",
                  transform: "translateY(1px)",
                }}
              />
            </p>
          );
          setButtonDisabled(true);
        }
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.data != null) {
            switch (error.response.data.message) {
              case "chargepoint not found":
                setErrorMsg("Diesen Ladepunkt gibt es nicht!");
                break;
              case "no wallet available":
                setErrorMsg(
                  "Diese Wallbox ist nicht für öffentliches Laden freigegeben!"
                );
                break;
              default:
                setErrorMsg("Fehler, bitte später erneut versuchen!");
                break;
            }
          } else {
            setErrorMsg("Fehler, bitte später erneut versuchen!");
          }
        } else {
          setErrorMsg("Fehler, bitte später erneut versuchen!");
        }
      });
  }, [reload]);

  useEffect(() => {
    const evse_id = props.match.params.evse_id;

    const evse_id_split = evse_id.split("*");

    const evse_id_short = `${evse_id_split[0]}*${evse_id_split[1]}*${evse_id_split[2]}`;

    // Log a message that the connection is open
    const noteOpened = () => console.log("Connection opened");

    const parseMessage = (event) => {
      //const message = JSON.parse(event.data);
      // Then do what you like with the message
      setReload(Math.random());
    };

    // Log a message that the connection has closed
    const noteClosed = () => console.log("Connection closed");

    // If an error occurs, throw the error
    const throwError = (error) => {
      console.error(error);
    };

    const socket_status = new Sarus({
      url: `${payment_wss_url}/${evse_id_short}`,
      eventListeners: {
        open: [noteOpened],
        message: [parseMessage],
        close: [noteClosed],
        error: [throwError],
      },
    });

    // component will unmount
    return () => {
      socket_status.disconnect();
    };
  }, []);

  //* -- FUNCTIONS -- *//
  function openingTimes() {
    const twentyfourseven = cpStatus?.user?.opening_times?.twentyfourseven;
    let content = "";
    const timesArray =
      typeof cpStatus?.user?.opening_times?.regular_hours === "object" &&
      cpStatus?.user?.opening_times?.regular_hours.length > 0
        ? cpStatus?.user?.opening_times?.regular_hours
        : [];

    const checkDayOpen = (day) => {
      let opening_times = [];

      timesArray.forEach((element) => {
        if (element.weekday === day) {
          opening_times.push(element.period_begin + " - " + element.period_end);
        }
      });

      if (opening_times.length === 0) {
        opening_times.push("Geschlossen");
      }
      return opening_times;
    };

    if (twentyfourseven) {
      content = "Diesen Ladepunkt ist durchgehend geöffnet.";
    } else {
      content = (
        <>
          <Row>
            <Col span={12}>Montag:</Col>
            <Col span={12}>
              {checkDayOpen(1).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Dienstag:</Col>
            <Col span={12}>
              {checkDayOpen(2).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Mittwoch:</Col>
            <Col span={12}>
              {checkDayOpen(3).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Donnerstag:</Col>
            <Col span={12}>
              {checkDayOpen(4).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Freitag:</Col>
            <Col span={12}>
              {checkDayOpen(5).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Samstag:</Col>
            <Col span={12}>
              {checkDayOpen(6).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>Sonntag:</Col>
            <Col span={12}>
              {checkDayOpen(7).map((item) => {
                return <div>{item}</div>;
              })}
            </Col>
          </Row>
        </>
      );
    }
    Modal.info({
      title: "Öffnungszeiten",
      content: content,
    });
  }

  const getIsOpen = () => {
    if (cpStatus?.user?.open) {
      return <p className="welcome-content-subinfos">Geöffnet</p>;
    } else {
      return (
        <p className="welcome-content-subinfos">
          Geschlossen{" "}
          <InfoCircleFilled
            style={{
              fontSize: "16px",
              transform: "translateY(1px)",
            }}
            onClick={openingTimes}
          />
        </p>
      );
    }
  };

  //* --  RETURN  -- *//
  return (
    <div className="welcome-background">
      {
        // Header
      }
      <div className="welcome-header-wrapper">
        <div style={{ width: "100%" }}>
          <QrcodeOutlined className="welcome-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="welcome-logo" />
          <p className="welcome-content-headline">QR-Payment</p>
        </div>
      </div>

      {
        // Content
      }
      <div className="welcome-content-wrapper">
        <div style={{ width: "100%" }}>
          <p className="welcome-content-station-evseid">
            {props.match.params.evse_id}
          </p>
          <p className="welcome-content-station-name">
            {errorMsg ? errorMsg : "Ladepunkt"}
          </p>

          {getIsOpen()}
          <p className="welcome-content-price-headline">
            Preis {cpStatus != null && (priceInclVat ? "inkl. Mwst." : "")}
          </p>
          <p className="welcome-content-price">
            {priceInclVat && priceInclVat.toFixed(2).replace(/\./g, ",") + " €"}
            {priceExclVat && priceExclVat.toFixed(2).replace(/\./g, ",") + " €"}
            <span style={{ fontSize: "14px" }}>/kWh</span>
          </p>
          <p className="welcome-content-subinfos">
            Mindestumsatz: 3,00€ | max. Umsatz: 29,99€
          </p>
        </div>
      </div>
      {
        // Interaction
      }
      <div className="welcome-interaction-wrapper">
        <div style={{ width: "100%" }}>
          <p className="welcome-interaction-headline">Status</p>
          <p className="welcome-interaction-text">{statusMsg}</p>

          <Button
            variant="outlined"
            id={
              buttonDisabled
                ? "welcome-interaction-button-disabled"
                : "welcome-interaction-button"
            }
            disabled={buttonDisabled}
            onClick={() =>
              history.push("/" + props.match.params.evse_id + "/payment")
            }
          >
            Ladevorgang starten
          </Button>
        </div>
      </div>
    </div>
  );
});
