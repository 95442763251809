/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { getUA } from "react-device-detect";

// Style import
import "../design/payment.scss";

// Ant Design import
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleFilled,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
} from "antd";

// Packages import
import Cleave from "cleave.js/react"; //For credit card validation and formating
import moment from "moment"; //For time formatting (with DatePicker)

// Services
import { publicIpv4 } from "public-ip";
import { QrPaymentService } from "../services/QrPayment.Service.js";

// Component import
import Sarus from "@anephenix/sarus";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";

/* ------------
      PAYMENT      
  ------------ */

const payment_wss_url = process.env.REACT_APP_PAYMENT_WSS_URL;
const uri_gtc = process.env.REACT_APP_URI_GTC; // general terms of conditions (allgemeine geschäftsbedingungen)
const uri_tos = process.env.REACT_APP_URI_TOS; // terms of use (nutzungsbedingungen)
const uri_privacy = process.env.REACT_APP_URI_PRIVACY; // privacy policy (datenschutzerklärung)

export default withRouter(function Payment(props) {
  //* --  STATES  -- *//
  const [isError, setIsError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedCar, setCheckedCar] = useState(false);
  const [status, setStatus] = useState();
  const [redirect, setRedirect] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [statusMsg, setStatusMsg] = useState("");
  const [reload, setReload] = useState();
  const [priceInclVat, setPriceInclVat] = useState();
  const [priceExclVat, setPriceExclVat] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [buyer, setBuyer] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    cardNumber: "",
    cardExpirationDate: "",
    cardCvx: "",
    IpAddress: "",
    BrowserInfo: {
      AcceptHeader: "*/*",
      JavaEnabled: false,
      Language: navigator.language || navigator.userLanguage,
      ColorDepth: Number(window.screen.colorDepth),
      ScreenHeight: Number(window.screen.height),
      ScreenWidth: Number(window.screen.width),
      TimeZoneOffset: new Date().getTimezoneOffset().toString(),
      UserAgent: getUA,
      JavascriptEnabled: true,
    },
    // address: {
    //   street: "",
    //   city: "",
    //   zip: "",
    //   region: "",
    // },
  });

  function warning() {
    Modal.warning({
      title: "Ladevorgang kann nicht gestartet werden",
      content: statusMsg,
    });
  }

  //* -- VARIABLES -- *//
  // Backend

  // Form layout
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const checkAGBLayout = {
    wrapperCol: { span: 24 },
  };

  // Date formating
  const dateFormat = "MM/YY";

  //* -- USE EFFECT -- *//
  useEffect(async () => {
    const evse_id = props.match.params.evse_id;

    try {
      const ip = await publicIpv4({
        fallbackUrls: ["https://ifconfig.co/ip"],
      });

      setBuyer({
        ...buyer,
        ["IpAddress"]: ip,
      });
    } catch (error) {
      console.error(error);
    }

    QrPaymentService.getStatus(evse_id)
      .then((response) => {
        setStatus(response);

        setPriceInclVat(response.user.price.incl_vat);
        setPriceExclVat(response.user.price.excl_vat);

        //! maybe refresh with some interval for opening_times?
        if (response.user.open == true) {
          if (response.chargepoint.connection_status === "online") {
            switch (response.chargepoint.ocpp_status) {
              case "Available":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist bereit{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(false);

                break;
              case "Preparing":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Fahrzeug angeschlossen{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(false);
                break;
              case "Charging":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Es findet bereits ein Ladevorgang statt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "SuspendedEVSE":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "SuspendedEV":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Finishing":
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Der Ladepunkt ist belegt.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Reserved":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist reserviert.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Unavailable":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              case "Faulted":
                setStatusMsg(
                  <p style={{ color: "#0ee077" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CheckCircleOutlined
                      style={{ fontSize: "16px", transform: "translateY(1px)" }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
              default:
                setStatusMsg(
                  <p style={{ color: "#ef5350" }}>
                    Ladepunkt ist nicht bereit. Bitte später erneut versuchen.{" "}
                    <CloseCircleOutlined
                      style={{
                        fontSize: "16px",
                        transform: "translateY(1px)",
                      }}
                    />
                  </p>
                );
                setButtonDisabled(true);
                break;
            }
          } else {
            setStatusMsg(
              <p style={{ color: "#ef5350" }}>
                Der Ladepunkt ist offline. Bitte später erneut versuchen.{" "}
                <CloseCircleOutlined
                  style={{
                    fontSize: "16px",
                    transform: "translateY(1px)",
                  }}
                />
              </p>
            );
            setButtonDisabled(true);
          }
        } else {
          setStatusMsg(
            <p style={{ color: "#ef5350" }}>
              Ladepunkt ist geschlossen.{" "}
              <CloseCircleOutlined
                style={{
                  fontSize: "16px",
                  transform: "translateY(1px)",
                }}
              />
            </p>
          );
          setButtonDisabled(true);
        }
      })
      .catch((error) => {
        if (error.response != null) {
          if (error.response.data != null) {
            switch (error.response.data.message) {
              case "chargepoint not found":
                setErrorMsg("Diesen Ladepunkt gibt es nicht!");
                break;
              case "no wallet available":
                setErrorMsg(
                  "Diese Wallbox ist nicht für öffentliches Laden freigegeben!"
                );
                break;
              default:
                setErrorMsg("Fehler, bitte später erneut versuchen!");
                break;
            }
          } else {
            setErrorMsg("Fehler, bitte später erneut versuchen!");
          }
        } else {
          setErrorMsg("Fehler, bitte später erneut versuchen!");
        }
      });
  }, [reload]);

  useEffect(() => {
    const evse_id = props.match.params.evse_id;

    const evse_id_split = evse_id.split("*");

    const evse_id_short = `${evse_id_split[0]}*${evse_id_split[1]}*${evse_id_split[2]}`;

    // Log a message that the connection is open
    const noteOpened = () => console.log("Connection opened");

    const parseMessage = (event) => {
      //const message = JSON.parse(event.data);
      // Then do what you like with the message

      setReload(Math.random());
    };

    // Log a message that the connection has closed
    const noteClosed = () => console.log("Connection closed");

    // If an error occurs, throw the error
    const throwError = (error) => {
      console.log(error);
    };

    const socket_status = new Sarus({
      url: `${payment_wss_url}/${evse_id_short}`,
      eventListeners: {
        open: [noteOpened],
        message: [parseMessage],
        close: [noteClosed],
        error: [throwError],
      },
    });

    // component will unmount
    return () => {
      socket_status.disconnect();
    };
  }, []);

  //* --  HANDLE FUNCTIONS  -- *//
  const handleChange = (prop) => (event) => {
    setErrorMsg("");

    switch (prop) {
      case "FirstName":
        event.target.value = event.target.value.replace(
          /[^a-zA-Z_'_´_`_ä_Ä_ö_Ö_ü_Ü_ß\s]/g,
          ""
        );
        setBuyer({ ...buyer, [prop]: event.target.value });
        break;
      case "LastName":
        event.target.value = event.target.value.replace(
          /[^a-zA-Z_'_´_`_ä_Ä_ö_Ö_ü_Ü_ß\s]/g,
          ""
        );
        setBuyer({ ...buyer, [prop]: event.target.value });
        break;
      case "Email":
        setBuyer({ ...buyer, [prop]: event.target.value });
        break;
      case "cardNumber":
        setBuyer({ ...buyer, [prop]: event.target.rawValue });
        break;
      case "cardExpirationDate":
        setBuyer({ ...buyer, [prop]: event.format("MMYY") });
        //console.log(event.format("MMYY"));
        break;
      case "cardCvx":
        event.target.value = event.target.value
          .replace(/[^0-9]/g, "")
          .replace(/-|\s/g, "");
        setBuyer({ ...buyer, [prop]: event.target.value });

        break;
      default:
    }
  };

  const handleCheckbox = () => {
    setChecked(!checked);
  };

  const handleCheckboxCar = () => {
    setCheckedCar(!checkedCar);
  };

  const startChargeErrorHandler = (error) => {
    //console.log(error);
    if (error.response != null) {
      if (error.response.data != null) {
        switch (error.response.data.message) {
          case "chargepoint not ready":
            setErrorResponse("Ladepunkt nicht bereit.");
            break;
          case "preauthorization failed":
            setErrorResponse("Autorisierung der Kreditkarte fehlgeschlagen.");
            break;
          case "chargepoint not found":
            setErrorResponse("Diesen Ladepunkt gibt es nicht.");
            break;
          case "postCardInfo failed":
            setErrorResponse("Die Kreditkartendaten sind fehlerhaft");
            break;
          case "closed":
            setErrorResponse("Dieser Ladepunkt ist momentan nicht geöffnet.");
            break;
          default:
            //console.log(error.response.data)
            if (error.response.data.message) {
              setErrorResponse(error.response.data.message);
            } else {
              setErrorResponse(error.response.data);
            }

            break;
        }
      } else {
        setErrorResponse("Fehler, bitte später erneut versuchen.");
      }
    } else {
      setErrorResponse("Fehler, bitte später erneut versuchen.");
    }

    setLoading(false);
  };

  const authorize = () => {
    //console.log(buyer);
    setLoading(true);
    const evse_id = props.match.params.evse_id;

    // trim whitespaces from buyer
    const buyer_trimmed = {
      FirstName: buyer.FirstName.trim(),
      LastName: buyer.LastName.trim(),
      Email: buyer.Email.trim(),
      cardNumber: buyer.cardNumber.trim(),
      cardExpirationDate: buyer.cardExpirationDate.trim(),
      cardCvx: buyer.cardCvx.trim(),
      IpAddress: buyer.IpAddress.trim(),
      BrowserInfo: buyer.BrowserInfo,
    };

    QrPaymentService.startCharging(evse_id, buyer_trimmed)
      .then((response) => {
        const session_id = response.session._id;

        if (
          response.session.preauthorization.SecureModeNeeded &&
          response.session.preauthorization.SecureModeRedirectURL
        ) {
          //setRedirect(response.session.preauthorization.SecureModeRedirectURL);
          window.location =
            response.session.preauthorization.SecureModeRedirectURL;
        } else {
          props.history.push("/" + evse_id + "/" + session_id + "/status");
        }

        setLoading(false);
      })
      .catch((error) => {
        startChargeErrorHandler(error);
      });
  };

  const disabledButton = () => {
    // validation
    if (status != null) {
      if (
        //TODO Validation needs to be done with ant form, so this manual validation can be deletedƒ
        buyer.FirstName.length > 0 &&
        buyer.LastName.length > 0 &&
        buyer.Email.length > 0 &&
        buyer.Email.match(
          // eslint-disable-next-line no-useless-escape
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        ) &&
        buyer.cardNumber.length > 11 &&
        buyer.cardNumber.length < 17 &&
        buyer.cardExpirationDate.length > 3 &&
        buyer.cardExpirationDate.length < 5 &&
        buyer.cardCvx.length > 0 &&
        /*
        buyer.address.street.length > 0 &&
        buyer.address.region.length > 0 &&
        buyer.address.zip.length > 0 &&
        buyer.address.city.length > 0 &&
        */

        checked === true &&
        checkedCar === true &&
        !buttonDisabled
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onFinish = (values) => {
    //console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  //* --  RETURN  -- *//

  // if (redirect) {
  //   return (
  //     window.location = redirect
  //   );
  // } else {
  return (
    <div className="payment-background">
      {
        // Header
      }
      <div className="payment-header-wrapper">
        <div style={{ width: "100%" }}>
          <QrcodeOutlined className="payment-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="payment-logo" />
          <p className="payment-content-headline">QR-Payment</p>
        </div>
      </div>
      {
        // Charging station
      }
      <div>
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Ladepunkt
        </p>
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col span={10}>
            <p>Stationsname:</p>
          </Col>
          <Col span={14}>
            <p>{errorMsg.length > 0 ? errorMsg : "Ladepunkt"}</p>
          </Col>
        </Row>
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col span={10}>
            <p>EVSE ID:</p>
          </Col>
          <Col span={14}>
            <p>{status ? props.match.params.evse_id : "unbekannt"}</p>
          </Col>
        </Row>
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col span={10}>
            <p>
              Preis
              {status != null && (priceInclVat ? "inkl. Mwst." : "")}
            </p>
          </Col>
          <Col span={14}>
            <p>
              {priceInclVat &&
                priceInclVat.toFixed(2).replace(/\./g, ",") + " €/kWh"}
              {priceExclVat &&
                priceExclVat.toFixed(2).replace(/\./g, ",") + " €/kWh"}
            </p>
          </Col>
        </Row>
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col span={10}>
            <p>Status:</p>
          </Col>

          {statusMsg && (
            <Col span={14}>
              <p style={{ color: "#0f0" }}>
                {statusMsg}
                {/* {!buttonDisabled && <CheckCircleOutlined
                    style={{ fontSize: "16px", transform: "translateY(1px)" }}
                  />} */}
              </p>
            </Col>
          )}

          {errorMsg && (
            <>
              <Col span={11}>
                <p style={{ color: "#f00" }}>
                  {errorMsg}
                  <CloseCircleOutlined
                    style={{
                      fontSize: "16px",
                      transform: "translateY(1px)",
                    }}
                  />
                </p>
              </Col>
              <Col span={3}>
                <Space>
                  <InfoCircleFilled
                    style={{
                      fontSize: "16px",
                      transform: "translateY(1px)",
                    }}
                    onClick={warning}
                  />
                </Space>
              </Col>
            </>
          )}
          {!status && (
            <Col span={14}>
              <p style={{ color: "#f00" }}>
                Status nicht bekannt{" "}
                <CloseCircleOutlined
                  style={{
                    fontSize: "16px",
                    transform: "translateY(1px)",
                  }}
                />
              </p>
            </Col>
          )}
        </Row>
      </div>

      {
        // Pay form
      }
      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          paddingTop: "30px",
        }}
      >
        Bezahlung mit Kreditkarte
      </p>
      <div className="payment-form-wrapper">
        <div>
          <Form
            {...layout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="payment-form"
            hideRequiredMark
          >
            <p
              style={{
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Persönliche Daten
            </p>

            <Form.Item
              label="Vorname"
              name="vorname"
              rules={[
                {
                  required: true,
                  message: "Bitte gib deinen Vornamen an!",
                },
              ]}
              className="payment-form-item"
            >
              <Input
                value={buyer.FirstName}
                onChange={handleChange("FirstName")}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Nachname"
              name="nachname"
              rules={[
                {
                  required: true,
                  message: "Bitte gib deinen Nachnamen an!",
                },
              ]}
              className="payment-form-item"
            >
              <Input
                value={buyer.LastName}
                onChange={handleChange("LastName")}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="E-Mail"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Bitte gib deine E-Mail an!",
                },
              ]}
              className="payment-form-item"
            >
              <Input
                value={buyer.Email}
                onChange={handleChange("Email")}
                size="large"
              />
            </Form.Item>
            {/*
              //! ADDRESS FORM
              <p
                style={{
                  fontWeight: "600",
                  textAlign: "center",
                  paddingTop: "25px"
                }}
              >
                Rechnungsadresse
              </p>
              <Form.Item
                label="Straße"
                name="street"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib deine Straße an!",
                  },
                ]}
                className="payment-form-item"
              >
                <Input
                  value={buyer.address.street}
                  onChange={handleChange("street")}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Ort"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib deinen Ort an!",
                  },
                ]}
                className="payment-form-item"
              >
                <Input
                  value={buyer.address.city}
                  onChange={handleChange("city")}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="PLZ"
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib deine PLZ an!",
                  },
                ]}
                className="payment-form-item"
              >
                <Input
                  value={buyer.address.zip}
                  onChange={handleChange("zip")}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Bundesland"
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Bitte gib dein Bundesland an!",
                  },
                ]}
                className="payment-form-item"
              >
                <Input
                  value={buyer.address.region}
                  onChange={handleChange("region")}
                  size="large"
                />
              </Form.Item>
              */}
            <p
              style={{
                fontWeight: "600",
                textAlign: "center",
                paddingTop: "25px",
              }}
            >
              Zahlungsdaten
            </p>
            <Form.Item
              label="Kreditkartennummer"
              name="kreditkartennummer"
              rules={[
                {
                  required: true,
                  message: "Bitte gib deine Kreditkartennummer an!",
                },
              ]}
              className="payment-form-item"
            >
              <Cleave
                className="ant-input"
                options={{ creditCard: true }}
                onChange={handleChange("cardNumber")}
                style={{
                  height: "40px",
                  fontSize: "16px",
                  padding: "6.5px 11px",
                }}
              />
            </Form.Item>
            <Form.Item
              label="gültig bis"
              name="gültigkeit"
              rules={[
                {
                  required: true,
                  message: "Bitte gib das Ablaufdatum deiner Kreditkarte an!",
                },
              ]}
              className="payment-form-item"
            >
              <DatePicker
                size="large"
                picker="month"
                onChange={handleChange("cardExpirationDate")}
                format={dateFormat}
                inputReadOnly={true}
                allowClear={false}
                disabledDate={(current) => {
                  return current && current < moment().endOf("day");
                }}
                placeholder=""
              />
            </Form.Item>
            <Form.Item
              label={<p style={{ margin: "0", fontSize: "12px" }}>CVC</p>}
              name="CVC"
              rules={[
                {
                  required: true,
                  message:
                    "Bitte gib den Sicherheitscode deiner Kreditkarte an!",
                },
              ]}
              className="payment-form-item"
            >
              <Input
                value={buyer.cardCvx}
                onChange={handleChange("cardCvx")}
                size="large"
              />
            </Form.Item>
            <Form.Item
              {...checkAGBLayout}
              name="check"
              rules={[
                {
                  required: true,
                  message: "Bitte akzeptiere unsere AGB!",
                },
              ]}
              style={{ margin: "30px 0px" }}
            >
              <Checkbox
                onChange={handleCheckbox}
                checked={checked}
                className="payment-form-checkbox"
              />
              Ich akzeptiere die{" "}
              <a href={uri_gtc} target="_blank" rel="noreferrer">
                AGB
              </a>
              ,{" "}
              <a href={uri_privacy} target="_blank" rel="noreferrer">
                Datenschutzbedingungen
              </a>{" "}
              und die{" "}
              <a href={uri_tos} target="_blank" rel="noreferrer">
                Nutzungsbedingungen
              </a>
              . <b>29,99€</b> werden auf meiner Kreditkarte <b>reserviert</b>.
              Nach dem Ladevorgangs wird der berechnete Betrag eingezogen und
              die Reservierung aufgehoben.
            </Form.Item>
            <Form.Item
              {...checkAGBLayout}
              name="check"
              rules={[
                {
                  required: true,
                  message:
                    "Bitte bestätige, dass du ein Auto angeschlossen hast!",
                },
              ]}
              style={{ margin: "30px 0px" }}
            >
              <Checkbox
                onChange={handleCheckboxCar}
                checked={checkedCar}
                className="payment-form-checkbox"
              />
              Ich bestätige, dass ein Auto an der Ladestation angeschlossen ist
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* <div className="payment-text-wrapper">
              <p>{statusMsg} A</p>
            </div>
        <div className="payment-text-wrapper">
          {errorMsg.length > 0 && <p style={{ color: "red" }}>{errorMsg}</p>}
        </div>
          */}
      {errorResponse.length > 0 && (
        <Alert
          message={errorResponse}
          type="error"
          style={{ width: "80%", margin: "0px auto 20px auto" }}
        />
      )}

      <p className="payment-price-info">
        {"Aktueller Preis: "}
        {priceInclVat && priceInclVat.toFixed(2).replace(/\./g, ",") + " €/kWh"}
        {priceExclVat && priceExclVat.toFixed(2).replace(/\./g, ",") + " €/kWh"}
      </p>

      <div className="payment-button-wrapper">
        <Button
          disabled={loading || disabledButton()}
          onClick={authorize}
          size="large"
          shape="round"
          type="primary"
        >
          <p>{loading ? "Bitte warten" : "Ladevorgang starten"}</p>
        </Button>
      </div>
    </div>
  );
  //};
});
