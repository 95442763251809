/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

// Style import
import "../design/status.scss";

// Ant Desin import
import { Col, Modal, Row, Button } from "antd";
import {
  InfoCircleFilled,
  NodeExpandOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

// Packages import
import moment from "moment";

// Services
import { QrPaymentService } from "../services/QrPayment.Service.js";

// Component import
import Sarus from "@anephenix/sarus";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";

/* ------------
      STATUS      
  ------------ */

const payment_wss_url = process.env.REACT_APP_PAYMENT_WSS_URL;

export default withRouter(function Status(props) {
  //* --  STATES  -- *//
  const [int, setInt] = useState();
  const [int2, setInt2] = useState();
  const [reload, setReload] = useState();
  const [errorMsg, setErrorMsg] = React.useState("");
  const [duration, setDuration] = useState("00:00:00");
  const [statusMsg, setStatusMsg] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [session, setSession] = useState();

  //* -- VARIABLES -- *//
  // Backend

  //* -- USE EFFECT -- *//

  useEffect(() => {
    const evse_id = props.match.params.evse_id;
    const session_id = props.match.params.session_id;

    QrPaymentService.getSession(evse_id, session_id)
      .then((response) => {
        setSession(response.session);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            switch (error.response.data.message) {
              case "session not found":
                setStatusMsg("Ladevorgang nicht gefunden");
                break;
              default:
                setStatusMsg("Fehler, Bitte versuche es später wieder");
                break;
            }
          } else {
            setStatusMsg("Fehler, Bitte versuche es später wieder");
          }
        } else {
          setStatusMsg("Fehler, Bitte versuche es später wieder");
        }
      });
  }, [reload]);

  useEffect(() => {
    const evse_id = props.match.params.evse_id;
    const session_id = props.match.params.session_id;

    const evse_id_split = evse_id.split("*");

    const evse_id_short = `${evse_id_split[0]}*${evse_id_split[1]}*${evse_id_split[2]}`;

    // Log a message that the connection is open
    const noteOpened = () => {
      console.log("Connection opened");

      // reload data manually if websocket is open, because data could already be newer
      setReload(Math.random());
    };

    const parseMessage = (event) => {
      //const message = JSON.parse(event.data);
      // Then do what you like with the message

      setReload(Math.random());
    };

    // Log a message that the connection has closed
    const noteClosed = () => console.log("Connection closed");

    // If an error occurs, throw the error
    const throwError = (error) => {
      console.log(error);
    };

    const socket_session = new Sarus({
      url: `${payment_wss_url}/${evse_id_short}/${session_id}`,
      eventListeners: {
        open: [noteOpened],
        message: [parseMessage],
        close: [noteClosed],
        error: [throwError],
      },
    });

    // component will unmount
    return () => {
      socket_session.disconnect();
    };
  }, []);

  // refresh for live session (interpolate kwh and total cost)
  //useEffect(() => {
  //  const timeout = 1000;
  //  if (session != null) {
  //    //interpolate kwh
  //    const start_date_time = session.data.start_date_time;
  //    const duration = (new Date() - new Date(start_date_time));
  //    const duration_spec = duration / timeout;
  //    const kwh = Number(session.data.kwh);
  //    let gradient;
  //
  //    if (kwh != 0) {
  //      gradient = kwh / duration_spec;
  //    } else {
  //      gradient = 0.002 * (1000 / timeout);
  //    };
  //
  //    // interpolate total_cost
  //    const price = session.tariff_data.data.elements[0].price_components[0].price;
  //    let excl_vat = Number(price * kwh);
  //    let min_price
  //
  //    if (session.tariff_data.data.min_price != null) {
  //      min_price = session.tariff_data.data.min_price.excl_vat;
  //
  //      if (excl_vat < min_price) {
  //        excl_vat = 1;
  //      };
  //    };
  //
  //    session.data.kwh = Number(session.data.kwh + gradient);
  //    session.data.total_cost.excl_vat = Number(excl_vat);
  //
  //    setDuration(new Date(duration).toISOString().substr(11, 8));
  //    //setSession(session);
  //  };
  //
  //  const to = setTimeout(() => {
  //    setInt(Math.random());
  //  }, timeout);
  //
  //  // component will unmount
  //  return () => {
  //    clearTimeout(to);
  //  };
  //}, [int]);

  const stopCharging = () => {
    const evse_id = props.match.params.evse_id;
    const session_id = props.match.params.session_id;

    setLoading(true);
    setErrorMsg("");

    QrPaymentService.stopCharging(evse_id, session_id)
      .then((response) => {
        setSession(response.session);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            switch (error.response.data.message) {
              case "not ready":
                // todo: find out
                break;
              default:
                setErrorMsg("Fehler, Bitte versuche es später wieder");
                break;
            }
          } else {
            setErrorMsg("Fehler, Bitte versuche es später wieder");
          }
        } else {
          setErrorMsg("Fehler, Bitte versuche es später wieder");
        }
        setLoading(false);
      });
  };

  //* --  RETURN  -- *//
  return (
    <div className="status-background">
      {
        // Header
      }
      <div className="status-header-wrapper">
        <div style={{ width: "100%" }}>
          <QrcodeOutlined className="status-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="status-logo" />
          <p className="status-content-headline">QR-Payment</p>
        </div>
      </div>
      {
        // Info
      }
      {
        // Charging station
      }
      <div>
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Ladevorgang
        </p>
        {/* <Row style={{ width: "80%", margin: "auto" }}>
          <Col span={12}>
            <p>Stationname:</p>
          </Col>
          <Col span={12}>
            {
              //! Name needs to be fetched
            }
            <p> {session.location.name}</p>
          </Col>
        </Row> */}

        {session && (
          <>
            <Row style={{ width: "80%", margin: "auto" }}>
              <Col span={12}>
                <p>Vorgangsnummer:</p>
              </Col>
              <Col span={12}>
                <p>{session.preauthorization.Id}</p>
              </Col>
            </Row>
            <Row style={{ width: "80%", margin: "auto" }}>
              <Col span={12}>
                <p>Bezahlungsstatus:</p>
              </Col>
              <Col span={12}>
                {session && session.preauthorization.Status === "CREATED" && (
                  <p style={{ color: "#ffd500" }}>
                    Autorisierung wird vorbereitet
                  </p>
                )}
                {session && (
                  <>
                    {session.payin && session.transfer ? (
                      session.payin.Status === "SUCCEEDED" &&
                      session.transfer.Status === "SUCCEEDED" && (
                        <p style={{ color: "#0f0" }}>bezahlt</p>
                      )
                    ) : (
                      <>
                        {session &&
                          session.preauthorization.Status === "SUCCEEDED" && (
                            <p style={{ color: "#ffd500" }}>
                              Bezahlung autorisiert, ausstehend
                            </p>
                          )}
                        {session &&
                          session.preauthorization.Status === "FAILED" && (
                            <p style={{ color: "#ff0000" }}>
                              Autorisierung fehlgeschlagen
                            </p>
                          )}
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {session && session.preauthorization.Status === "FAILED" && (
              <>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Fehlercode:</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      [{session.preauthorization.ResultCode}]{" "}
                      {session.preauthorization.ResultMessage}
                    </p>
                  </Col>
                </Row>
              </>
            )}

            {session.preauthorization.Status === "FAILED" ? (
              <>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Ladestatus:</p>
                  </Col>
                  <Col span={12}>
                    <p style={{ color: "#ff0000" }}>Ladevorgang abgebrochen</p>
                  </Col>
                </Row>
                <br />
                <div className="status-button-wrapper">
                  <Link
                    to={"/" + props.match.params.evse_id + "/"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="welcome-button">
                      <p>Neuen Ladevorgang starten</p>
                    </Button>
                  </Link>
                </div>
              </>
            ) : session.transaction ? (
              <>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Ladestatus:</p>
                  </Col>
                  {session && !session.transaction.stop.timestamp ? (
                    session.RemoteStopTransaction ? (
                      <>
                        {session.RemoteStopTransaction.Request &&
                          !session.RemoteStopTransaction.Response && (
                            <Col span={12}>
                              <p style={{ color: "#ffd500" }}>
                                Ladebefehl (Stop) gesendet
                              </p>
                            </Col>
                          )}

                        {session.RemoteStopTransaction.Response && (
                          <>
                            {session.RemoteStopTransaction.Response.status ===
                            "Accepted" ? (
                              <Col span={12}>
                                <p style={{ color: "#0f0" }}>
                                  Ladebefehl (Stop) akzeptiert
                                </p>
                              </Col>
                            ) : (
                              <>
                                <Col span={12}>
                                  <p style={{ color: "#ff0000" }}>
                                    Ladebefehl (Stop) verweigert
                                  </p>
                                </Col>
                              </>
                            )}

                            {/* {session.RemoteStartTransaction.Response.status === "Rejected" && (
                            
                          )} */}
                          </>
                        )}
                      </>
                    ) : (
                      <Col span={12}>
                        <p style={{ color: "#0f0" }}>Ladevorgang aktiv </p>
                      </Col>
                    )
                  ) : (
                    <>
                      <Col span={12}>
                        <p>Ladevorgang beendet</p>
                      </Col>
                    </>
                  )}
                </Row>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>
                      {session &&
                        session.transaction.stop.reason &&
                        "beendet durch:"}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      {session &&
                        session.transaction.stop.reason &&
                        session.transaction.stop.reason}
                    </p>
                  </Col>
                </Row>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Start:</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      {session &&
                        moment(session.transaction.start.timestamp).format(
                          "DD.MM.YYYY HH:mm"
                        ) + " Uhr"}
                    </p>
                  </Col>
                </Row>
                {session && session.transaction.stop.timestamp && (
                  <Row style={{ width: "80%", margin: "auto" }}>
                    <Col span={12}>
                      <p>Ende:</p>
                    </Col>
                    <Col span={12}>
                      <p>
                        {" "}
                        {moment(
                          session && session.transaction.stop.timestamp
                        ).format("DD.MM.YYYY HH:mm") + " Uhr"}
                      </p>
                    </Col>
                  </Row>
                )}

                {session.wallet.subject_to_vat ? (
                  <Row style={{ width: "80%", margin: "auto" }}>
                    <Col span={12}>
                      <p>Preis inkl. Mwst.:</p>
                    </Col>
                    <Col span={12}>
                      <p>{session.wallet.price.incl_vat.toFixed(2)} €/kWh</p>
                    </Col>
                  </Row>
                ) : (
                  <Row style={{ width: "80%", margin: "auto" }}>
                    <Col span={12}>
                      <p>Preis:</p>
                    </Col>
                    <Col span={12}>
                      <p>{session.wallet.price.excl_vat.toFixed(2)} €/kWh</p>
                    </Col>
                  </Row>
                )}

                {session.wallet.subject_to_vat
                  ? (
                      (session.transaction.amount / 1000) *
                      session.wallet.price.incl_vat
                    ).toFixed(2) < session.total_cost.incl_vat && (
                      <Row style={{ width: "80%", margin: "auto" }}>
                        <Col span={12}>
                          <p>Umsatz inkl. Mwst.:</p>
                        </Col>
                        <Col span={12}>
                          <p>
                            {(
                              (session.transaction.amount / 1000) *
                              session.wallet.price.incl_vat
                            ).toFixed(2)}{" "}
                            €
                          </p>
                        </Col>
                      </Row>
                    )
                  : (
                      (session.transaction.amount / 1000) *
                      session.wallet.price.excl_vat
                    ).toFixed(2) < session.total_cost.excl_vat && (
                      <Row style={{ width: "80%", margin: "auto" }}>
                        <Col span={12}>
                          <p>Umsatz:</p>
                        </Col>
                        <Col span={12}>
                          <p>
                            {(
                              (session.transaction.amount / 1000) *
                              session.wallet.price.excl_vat
                            ).toFixed(2)}{" "}
                            €
                          </p>
                        </Col>
                      </Row>
                    )}

                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>
                      Endbetrag{" "}
                      {session && session.wallet.subject_to_vat === true
                        ? "inkl. Mwst.:"
                        : ":"}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p>
                      {" "}
                      {session && session.wallet.subject_to_vat === true
                        ? session && session.total_cost.incl_vat.toFixed(2)
                        : session &&
                          session.total_cost.excl_vat.toFixed(2)}{" "}
                      €
                    </p>
                  </Col>
                </Row>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Dauer:</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      {session &&
                        moment.duration(session.transaction.duration).hours() +
                          " h " +
                          moment
                            .duration(session.transaction.duration)
                            .minutes() +
                          " m " +
                          moment
                            .duration(session.transaction.duration)
                            .seconds() +
                          " s "}
                    </p>
                  </Col>
                </Row>
                <Row style={{ width: "80%", margin: "auto" }}>
                  <Col span={12}>
                    <p>Menge:</p>
                  </Col>
                  <Col span={12}>
                    <p>
                      {session &&
                        (session.transaction.amount / 1000).toFixed(3)}{" "}
                      kWh
                    </p>
                  </Col>
                </Row>

                <div className="payment-text-wrapper">
                  {errorMsg.length > 0 && (
                    <p style={{ color: "red" }}>{errorMsg}</p>
                  )}
                </div>
                <div className="status-button-wrapper">
                  {session && !session.transaction.stop.timestamp && (
                    <Button
                      className="status-button"
                      disabled={loading}
                      onClick={stopCharging}
                    >
                      <p>{loading ? "Bitte warten" : "Ladevorgang beenden"}</p>
                    </Button>
                  )}
                  {session && session.transaction.stop.timestamp && (
                    <Link
                      to={"/" + props.match.params.evse_id + "/"}
                      style={{ textDecoration: "none" }}
                    >
                      <Button className="welcome-button">
                        <p>Neuen Ladevorgang starten</p>
                      </Button>
                    </Link>
                  )}
                </div>
              </>
            ) : session.RemoteStartTransaction ? (
              <>
                {!session.RemoteStartTransaction &&
                  !session.RemoteStopTransaction && (
                    <>
                      <Row style={{ width: "80%", margin: "auto" }}>
                        <Col span={12}>
                          <p>Ladestatus:</p>
                        </Col>

                        <Col span={12}>
                          <p style={{ color: "#ffd500" }}>
                            Ladevorgang wird vorbereitet{" "}
                          </p>
                        </Col>
                      </Row>
                    </>
                  )}

                {session.RemoteStartTransaction.Request &&
                  !session.RemoteStartTransaction.Response && (
                    <Row style={{ width: "80%", margin: "auto" }}>
                      <Col span={12}>
                        <p>Ladestatus:</p>
                      </Col>

                      <Col span={12}>
                        <p style={{ color: "#ffd500" }}>
                          Ladebefehl (Start) gesendet
                        </p>
                      </Col>
                    </Row>
                  )}

                {session.RemoteStartTransaction.Response && (
                  <>
                    {session.RemoteStartTransaction.Response.status ===
                    "Accepted" ? (
                      <Row style={{ width: "80%", margin: "auto" }}>
                        <Col span={12}>
                          <p>Ladestatus:</p>
                        </Col>

                        <Col span={12}>
                          <p style={{ color: "#0f0" }}>
                            Ladebefehl (Start) akzeptiert
                          </p>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row style={{ width: "80%", margin: "auto" }}>
                          <Col span={12}>
                            <p>Ladestatus:</p>
                          </Col>

                          <Col span={12}>
                            <p style={{ color: "#ff0000" }}>
                              Ladebefehl (Start) verweigert
                            </p>
                          </Col>
                        </Row>
                        <div className="status-button-wrapper">
                          <Link
                            to={"/" + props.match.params.evse_id + "/"}
                            style={{ textDecoration: "none" }}
                          >
                            <Button className="welcome-button">
                              <p>Neuen Ladevorgang starten</p>
                            </Button>
                          </Link>
                        </div>
                      </>
                    )}

                    {/* {session.RemoteStartTransaction.Response.status === "Rejected" && (
                          
                        )} */}
                  </>
                )}
              </>
            ) : (
              <Row style={{ width: "80%", margin: "auto" }}>
                <Col span={12}>
                  <p>Ladestatus:</p>
                </Col>

                <Col span={12}>
                  <p style={{ color: "#ffd500" }}>
                    Ladevorgang wird vorbereitet{" "}
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
});
