import axios from "axios";
import qs from "qs";

const url = `${process.env.REACT_APP_PAYMENT_API_URL}`;

export const QrPaymentService = {
  getStatus: (evse_id) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url + "/chargepoint/get/" + evse_id, { headers })
        .then((result) => {
          //console.log(result.data)
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSession: (evse_id, session_id) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .get(url + "/charge/get/" + session_id, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startCharging: (evse_id, buyer) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url + "/charge/start/" + evse_id, buyer, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startCharging1: (evse_id, body1) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url + "/charge/start1/" + evse_id, body1, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  startCharging2: (evse_id, body2) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url + "/charge/start2/" + evse_id, body2, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  stopCharging: (evse_id, session_id) => {
    const headers = {
      "content-type": "application/json",
    };

    return new Promise((resolve, reject) => {
      axios
        .put(url + "/charge/stop/" + session_id, { empty: "body" }, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postCardInfo: (CardRegistrationURL, cardData) => {
    // const params = new URLSearchParams();

    // for (let key in cardData) {
    //   params.append(key, cardData[key]);
    // }

    // console.log(params);

    cardData = qs.stringify(cardData);

    const headers = {
      "content-type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    };

    return new Promise((resolve, reject) => {
      axios
        .put(CardRegistrationURL, cardData, { headers })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
      //   fetch(CardRegistrationURL, {
      //     method: "PUT",
      //     mode: "cors",
      //     body: cardData,
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //       "Access-Control-Allow-Origin": "http://localhost:3000",
      //     },
      //   })
      //     .then((response) => {
      //       console.log(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
    });
  },
};
