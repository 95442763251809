/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

// Style import
import "../design/defaultpage.scss";

// Icons import
import { QrcodeOutlined } from "@ant-design/icons";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";
import cIQLogoBlack from "../resource/20200923_chargeIQ_Logo_black_small.png";

/* ------------
      DEFAULT PAGE      
  ------------ */

export default withRouter(function DefaultPage(props) {
  //* --  RETURN  -- *//
  return (
    <div className="defaultpage-background">
      <div className="defaultpage-content-wrapper">
        <div>
          <QrcodeOutlined className="defaultpage-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="defaultpage-logo" />
          <p className="defaultpage-content-headline">QR-Payment</p>
          <p className="defaultpage-content-text">
            Schnell und unkompliziert! Mit der chargeIQ QR-Payment Web-App
            direkt an der Ladesäule mit dem Smartphone bezahlen - ganz ohne
            Registrierung. Einfach QR-Code an der Säule scannen und loslegen.
          </p>
        </div>
      </div>
    </div>
  );
});
