/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

// Style import
//import "../design/finish.scss";

// Component import

// Services
import { QrPaymentService } from "../services/QrPayment.Service.js";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";
import { QrcodeOutlined } from "@ant-design/icons";
import { Col, Row, Button } from "antd";

/* ------------
      FINISH      
  ------------ */

export default withRouter(function Finish(props) {
  /* --  STATES  -- */
  const [session, setSession] = React.useState();
  const [evseId, setEvseId] = React.useState(props.match.params.evse_id);

  /* --  FUNCTIONS  -- */
  useEffect(() => {
    const session_id = props.match.params.session_id;

    //SessionService.getSession(session_id).then((response) => {
    //  const session = response;
    //  setSession(session);
    //  const client_id = response.auth_data.client_id;
    //
    //}).catch((error) => {
    //  console.log(error);
    //});

    // component will unmount
    return () => {};
  }, []);

  /* --  RETURN  -- */
  return (
    <div className="finish-background">
      {
        // Header
      }
      <div className="finish-header-wrapper">
        <div style={{ width: "100%" }}>
          <QrcodeOutlined className="finish-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="finish-logo" />
          <p className="finish-content-headline">QR-Payment</p>
        </div>
      </div>

      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
        }}
      >
        Vielen Dank!
      </p>

      <div className="finish-text-wrapper">
        <p className="finish-text">
          Dein Ladevorgang wurde erfolgreich beendet. Wir senden dir in Kürze
          deine Rechnung per Mail zu. Danke, dass du bei chargeIQ Home geladen
          hast. Bis zum nächsten mal!
        </p>
      </div>

      <p
        style={{
          textAlign: "center",
          fontSize: "20px",
          paddingTop: "15px"
        }}
      >
        Zusammenfassung
      </p>

      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Stationname:</p>
        </Col>
        <Col span={12}>
          <p> chargeIQ Büro</p>
        </Col>
      </Row>

      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Start:</p>
        </Col>
        <Col span={12}>
          <p>time</p>
        </Col>
      </Row>
      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Ende:</p>
        </Col>
        <Col span={12}>
          <p> time</p>
        </Col>
      </Row>

      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Betrag </p>
        </Col>
        <Col span={12}>
          <p> €</p>
        </Col>
      </Row>
      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Dauer:</p>
        </Col>
        <Col span={12}>
          <p>duration h</p>
        </Col>
      </Row>
      <Row style={{ width: "80%", margin: "auto" }}>
        <Col span={12}>
          <p>Menge:</p>
        </Col>
        <Col span={12}>
          <p>kWh</p>
        </Col>
      </Row>

      <div className="finish-button-wrapper">
        <Link
          to={"/" + props.match.params.id + "/"}
          style={{ textDecoration: "none" }}
        >
          <Button>
            <p>Neustart</p>
          </Button>
        </Link>
      </div>
    </div>
  );
});
