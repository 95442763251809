/* -------------
      IMPORTS  
  -------------- */
// React import
import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";

// Style import
import "../design/errorpage.scss";

// Icons import
import { QrcodeOutlined } from "@ant-design/icons";

//Ressource import
import cIQLogoWhite from "../resource/20200923_chargeIQ_Logo_white_small.png";
import cIQLogoBlack from "../resource/20200923_chargeIQ_Logo_black_small.png";

import { Icon, InlineIcon } from "@iconify/react";
import bxErrorCircle from "@iconify/icons-bx/bx-error-circle";

/* ------------
      ERROR PAGE      
  ------------ */

export default withRouter(function ErrorPage(props) {
  //* --  RETURN  -- *//
  return (
    <div className="errorpage-background">
      <div className="errorpage-content-wrapper" >
        <div>
          <QrcodeOutlined className="errorpage-content-qr" />
          <img src={cIQLogoWhite} alt="logo" className="errorpage-logo" />
          <p className="errorpage-content-headline">QR-Payment</p>
        </div>
      </div>
      <div className="errorpage-content-wrapper" >
        <div >
          <Icon icon={bxErrorCircle} className="errorpage-error-icon" />
          <p className="errorpage-error-text">{props.message}</p>
        </div>
      </div>
    </div>
  );
});
