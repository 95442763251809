/* -------------
      IMPORTS  
  -------------- */
// React import
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// Style import
import "./app.less";
import "./app.scss";

// Component import
import DefaultPage from "./pages/DefaultPage";
import ErrorPage from "./pages/ErrorPage";
import Finish from "./pages/Finish";
import Payment from "./pages/Payment";
import Status from "./pages/Status";
import Welcome from "./pages/Welcome";

// MaterialUI import
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

//Ressource import

// Material UI Theme Provider
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat, sans-serif"],
  },
  palette: {
    //chargeIQ Grün auf hellem Hintergrund
    primary: {
      main: "#282828",
    },
    //chargeIQ Grün auf dunklem Hintergrund

    secondary: {
      main: "#0EE077",
    },
  },
});

/* ------------
      APP      
  ------------ */

export default function App(props) {
  //* --  RETURN  -- *//
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/error" component={ErrorPage}></Route>
            <Route exact path="/:evse_id" component={Welcome}></Route>
            <Route exact path="/:evse_id/payment" component={Payment}></Route>
            <Route
              exact
              path="/:evse_id/:session_id/status"
              component={Status}
            ></Route>
            <Route
              exact
              path="/:evse_id/:session_id/finish"
              component={Finish}
            ></Route>
            <Route component={DefaultPage}></Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}
